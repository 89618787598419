<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Basic Badges</div>
                    <div class="card-body">
                        <h1>h1 - Example heading <span class="badge badge-secondary">New</span></h1>
                        <h2>h2 - Example heading <span class="badge badge-secondary">New</span></h2>
                        <h3>h3 - Example heading <span class="badge badge-secondary">New</span></h3>
                        <h4>h4 - Example heading <span class="badge badge-secondary">New</span></h4>
                        <h5>h5 - Example heading <span class="badge badge-secondary">New</span></h5>
                        <h6>h6 - Example heading <span class="badge badge-secondary">New</span></h6>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Contextual Variations</div>
                    <div class="card-body">
                        <div>
                            <span class="badge badge-primary">Primary</span>
                            <span class="badge badge-secondary ml-1">Secondary</span>
                            <span class="badge badge-danger ml-1">Danger</span>
                            <span class="badge badge-warning ml-1">Warning</span>
                            <span class="badge badge-success ml-1">Success</span>
                            <span class="badge badge-info ml-1">Info</span>
                            <span class="badge badge-light ml-1">Light</span>
                            <span class="badge badge-dark ml-1">Dark</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Pill Badges</div>
                    <div class="card-body">
                        <div>
                            <span class="badge badge-pill badge-primary">Primary</span>
                            <span class="badge badge-pill badge-secondary ml-1">Secondary</span>
                            <span class="badge badge-pill badge-danger ml-1">Danger</span>
                            <span class="badge badge-pill badge-warning ml-1">Warning</span>
                            <span class="badge badge-pill badge-success ml-1">Success</span>
                            <span class="badge badge-pill badge-info ml-1">Info</span>
                            <span class="badge badge-pill badge-light ml-1">Light</span>
                            <span class="badge badge-pill badge-dark ml-1">Dark</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Links Badges</div>
                    <div class="card-body">
                        <div>
                            <a href="#" class="badge badge-pill badge-primary">Primary</a>
                            <a href="#" class="badge badge-pill badge-secondary ml-1">Secondary</a>
                            <a href="#" class="badge badge-pill badge-danger ml-1">Danger</a>
                            <a href="#" class="badge badge-pill badge-warning ml-1">Warning</a>
                            <a href="#" class="badge badge-pill badge-success ml-1">Success</a>
                            <a href="#" class="badge badge-pill badge-info ml-1">Info</a>
                            <a href="#" class="badge badge-pill badge-light ml-1">Light</a>
                            <a href="#" class="badge badge-pill badge-dark ml-1">Dark</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Badges',
    }
</script>
